export const IDP_BASE_URL = 'IDP_BASE_URL';
export const OAUTH2_CLIENT_ID = 'OAUTH2_CLIENT_ID';
export const API_BASE_URL = 'API_BASE_URL';
export const SCOPES = 'SCOPES';
export const SUMMIT_API_BASE_URL = 'SUMMIT_API_BASE_URL';
export const MARKETING_API_BASE_URL = 'MARKETING_API_BASE_URL';
export const REGISTRATION_BASE_URL = 'REGISTRATION_BASE_URL';
export const AUTHZ_USER_GROUPS = 'AUTHZ_USER_GROUPS';
export const AUTHORIZED_DEFAULT_PATH = 'AUTHORIZED_DEFAULT_PATH';
export const STREAM_IO_API_KEY = 'STREAM_IO_API_KEY';
export const MUX_ENV_KEY = 'MUX_ENV_KEY';
export const SUPABASE_URL = "SUPABASE_URL";
export const SUPABASE_KEY = "SUPABASE_KEY";
export const CHAT_API_BASE_URL = "CHAT_API_BASE_URL";
export const LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS = "LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS";
export const OAUTH2_FLOW = 'OAUTH2_FLOW';
export const FRIENDLY_CAPTCHA_SITE_KEY = 'FRIENDLY_CAPTCHA_SITE_KEY';
export const SPONSORED_PROJECT_ID = 'SPONSORED_PROJECT_ID';
export const MEMBERS_SUBPROJECT_ID = 'MEMBERS_SUBPROJECT_ID';
export const TIMEINTERVALSINCE1970_API_URL = 'TIMEINTERVALSINCE1970_API_URL';

const processEnv = {
  IDP_BASE_URL: process.env.GATSBY_IDP_BASE_URL,
  SUMMIT_API_BASE_URL: process.env.GATSBY_SUMMIT_API_BASE_URL,
  API_BASE_URL: process.env.GATSBY_SUMMIT_API_BASE_URL,
  OAUTH2_FLOW: process.env.GATSBY_OAUTH2_FLOW,
  OAUTH2_CLIENT_ID: process.env.GATSBY_OAUTH2_CLIENT_ID,
  SCOPES: process.env.GATSBY_SCOPES,
  MARKETING_API_BASE_URL: process.env.GATSBY_MARKETING_API_BASE_URL,
  REGISTRATION_BASE_URL: process.env.GATSBY_REGISTRATION_BASE_URL,
  AUTHZ_USER_GROUPS: process.env.GATSBY_AUTHZ_USER_GROUPS,
  AUTHORIZED_DEFAULT_PATH: process.env.GATSBY_AUTHORIZED_DEFAULT_PATH,
  STREAM_IO_API_KEY: process.env.GATSBY_STREAM_IO_API_KEY,
  MUX_ENV_KEY: process.env.GATSBY_MUX_ENV_KEY,
  SUPABASE_URL: process.env.GATSBY_SUPABASE_URL,
  SUPABASE_KEY: process.env.GATSBY_SUPABASE_KEY,
  CHAT_API_BASE_URL: process.env.GATSBY_CHAT_API_BASE_URL,
  LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS: process.env.GATSBY_LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS || 60,
  FRIENDLY_CAPTCHA_SITE_KEY: process.env.GATSBY_FRIENDLY_CAPTCHA_SITE_KEY,
  SPONSORED_PROJECT_ID: process.env.GATSBY_SPONSORED_PROJECT_ID,
  MEMBERS_SUBPROJECT_ID: process.env.GATSBY_MEMBERS_SUBPROJECT_ID,
  TIMEINTERVALSINCE1970_API_URL: process.env.GATSBY_TIMEINTERVALSINCE1970_API_URL,
}

export const getEnvVariable = (name) => {
  let res = typeof window === 'object' ? window[name] : null;
  if (!res) {
    res = processEnv[name];
  }
  return res;
}

if (typeof window === 'object') {
  window.OAUTH2_FLOW = processEnv[OAUTH2_FLOW];
  window.OAUTH2_CLIENT_ID = processEnv[OAUTH2_CLIENT_ID];
  window.SCOPES = processEnv[SCOPES];
  window.IDP_BASE_URL = processEnv[IDP_BASE_URL];
  window.SUMMIT_API_BASE_URL = processEnv[SUMMIT_API_BASE_URL];
  window.API_BASE_URL = processEnv[API_BASE_URL];
  window.MARKETING_API_BASE_URL = processEnv[MARKETING_API_BASE_URL];
  window.REGISTRATION_BASE_URL = processEnv[REGISTRATION_BASE_URL];
  window.AUTHZ_USER_GROUPS = processEnv[AUTHZ_USER_GROUPS];
  window.AUTHORIZED_DEFAULT_PATH = processEnv[AUTHORIZED_DEFAULT_PATH];
  window.STREAM_IO_API_KEY = processEnv[STREAM_IO_API_KEY];
  window.MUX_ENV_KEY = processEnv[MUX_ENV_KEY];
  window.SUPABASE_URL = processEnv[SUPABASE_URL];
  window.SUPABASE_KEY = processEnv[SUPABASE_KEY];
  window.CHAT_API_BASE_URL = processEnv[CHAT_API_BASE_URL];
  window.LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS = processEnv[LIVE_EVENT_THUMBNAIL_GIF_CAPTURE_STARTS];
  window.SPONSORED_PROJECT_ID = processEnv[SPONSORED_PROJECT_ID];
  window.MEMBERS_SUBPROJECT_ID = processEnv[MEMBERS_SUBPROJECT_ID];
  window.TIMEINTERVALSINCE1970_API_URL = processEnv[TIMEINTERVALSINCE1970_API_URL];
}


